.landing {
    height: 320px;
    margin-bottom: 40px;
}

.landing .main-content {
    padding-top: 100px;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
	background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        linear-gradient(90deg, rgba(0, 255, 0, 0.2), rgba(255, 255, 255, 0.2), rgba(255, 0, 0, 0.2)),
        url('../../../../../assets/images/home-page_main-image.png');
}

.landing .top-label {
    margin: 0 0 10px 200px;
    color: white;
}

.landing .middle-label {
    margin: 0 0 10px 200px;
    font-weight: 600;
    color: white;
}

.landing .bottom-label {
    margin: 0 0 10px 200px;
    color: white;
}
