.top-nav-bar {
    top: 0;
    z-index: 999;
    position: -webkit-sticky;
    position: sticky;
    display: flex;
    flex-wrap: wrap;
    padding: 15px 20px;
    align-items: center;
    justify-content: space-between;
}

.top-nav-bar .left {
    min-width: 30%;
}

.top-nav-bar .left .phone-link {
    margin: 0 15px;
    color: black;
}

.top-nav-bar .image-flag {
    width: 20px;
    height: 20px;
    margin: 0 5px -4px 5px;
}

.top-nav-bar .center {
	width: 40%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.top-nav-bar .center .center-links {
    color: black;
}

.top-nav-bar .center .center-links .top-nav-link {
    margin: 0 15px;
}

.top-nav-bar .center .center-links .top-nav-link .top-nav-link-icon {
    margin: 0 5px -5px 5px;
}

.top-nav-bar .right {
	width: 20%;
	display: flex;
	justify-content: flex-end;
}

.top-nav-bar .right .image-flag {
	width: 30px;
	height: 30px;
	cursor: pointer;
}
