.perks {
    display: flex;
    margin-top: 60px;
    align-items: center;
    justify-content: center;
}

.perks-subsections {
    width: 80%;
    display: flex;
	max-width: 1600px;
    justify-content: center;
}

.perks-subsections .subsection {
    width: 20%;
    padding: 30px;
    margin: 0 10px;
    border-radius: 5px;
    background-color: #eeeeee;
}

.perks-subsections .subsection .subsection-image {
	width: 50px;
	height: 50px;
}
