.how-it-works-step-container-mobile {
	padding: 40px 0;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.how-it-works-step-container-mobile .hiw-contents-wrapper {
	width: 85%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.how-it-works-step-container-mobile .hiw-contents-wrapper .hiw-sub-header {
	width: 100%;
}

.how-it-works-step-container-mobile .hiw-contents-wrapper .hiw-header {
	width: 100%;
}

.how-it-works-step-container-mobile .hiw-contents-wrapper .hiw-contents-description {
	padding: 20px;
	margin-top: 10px;
}

.how-it-works-step-container-mobile .hiw-image-wrapper {
	width: 30%;
	display: flex;
	margin: 10px 0;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.how-it-works-step-container-mobile .hiw-image-wrapper img {
	width: 100%;
	height: 100%;
}

.how-it-works-step-container-mobile .hiw-arrow-wrapper {
	width: 20%;
	display: flex;
	rotate: 45deg;
	flex-wrap: wrap;
	margin-top: 30px;
	align-items: center;
	justify-content: space-evenly;
}

.how-it-works-step-container-mobile .hiw-arrow-wrapper.rotated {
	transform: scaleX(-1);
	rotate: -45deg;
}

.how-it-works-step-container-mobile .hiw-arrow-wrapper img {
	width: 100%;
	height: 100%;
}
