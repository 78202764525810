.add-unreg-order-form-title {
	padding-top: 30px;
}

.add-unreg-order-form {
	padding: 20px;
}

.add-unreg-order-form .add-unreg-form-input-field {
	padding: 5px 0;
}

.add-unreg-order-form .package-measurements {
	margin: 30px 0;
	padding: 10px;
	background-color: #efefef;
}

.add-unreg-order-form .package-measurements.processed {
	background-color: #e6ffe3 !important;
}

.add-unreg-order-form .package-measurements .package-measurements-top-wrapper {
	display: flex;
	justify-content: space-between;
}