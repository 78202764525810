.MuiDialog-paper {
	width: 100% !important;
}

.modal-container {
    padding: 20px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 2px 2px 10px black;
}

.modal-container.master-order {
	border: 3px solid red;
}

.modal-container .package-measurements {
	margin: 30px 0;
	padding: 10px;
	background-color: #efefef;
}

.modal-container .package-measurements.processed {
	background-color: #e6ffe3 !important;
}

.modal-container .package-measurements .package-measurements-top-wrapper {
	display: flex;
	justify-content: space-between;
}

.modal-container .action-buttons {
    display: flex;
    justify-content: flex-end;
}

.modal-container .action-buttons button {
    margin: 0 10px;
}

.modal-container .order-price {
	margin: 20px 0;
	padding: 5px 10px;
	border: 2px dotted black;
}

.modal-container .copiable-text {
	margin: 10px 0;
}

.modal-container .copiable-text .copy-to-clipboard-wrapper {
	border: 1px solid #dedede;
	border-radius: 5px;
	padding: 3px;
	margin-left: 5px;
	cursor: pointer;
}

.modal-container .copiable-text .copy-to-clipboard-wrapper:hover {
	border: 1px solid #7e7e7e;
}
