.login-container {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
}

.login-form-wrapper {
    width: 40%;
    height: 100%;
    margin-top: 30px;
}

.login-form-wrapper .header {
    width: 100%;
    font-weight: 600;
    margin-top: 20px;
}

.login-input-fields {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 20px 0 20px;
    justify-content: center;
}

.login-input-fields .helpers-section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.login-input-fields .login-input-field {
    width: 100%;
    margin: 10px 0 10px !important;
}

.login-input-fields .action-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0 10px !important;
}

.login-input-fields .action-buttons .action-button {
    margin: 0 10px;
}

/* MOBILE */
.login-container-mobile {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
}

.login-container-mobile .login-form-wrapper {
	width: 80%;
    height: 100%;
    margin-top: 30px;
}

.login-container-mobile .login-form-wrapper .header {
    margin-top: 20px;
}

.login-container-mobile .login-input-fields {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 20px 0 20px;
    justify-content: center;
}

.login-container-mobile .login-input-fields .helpers-section  {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.login-container-mobile .login-input-fields .login-input-field {
    width: 100%;
    margin: 10px 0 10px !important;
}

.login-container-mobile .login-input-fields .action-buttons {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 0 10px !important;
}

.login-container-mobile .login-input-fields .action-buttons .action-button {
	margin: 0 10px;
}