.top-nav-bar-mobile {
    top: 0;
	width: 100%;
    z-index: 999;
	height: 80px;
    display: flex;
    flex-wrap: wrap;
    position: fixed;
	padding: 5px 0;
    align-items: center;
    background-color: white;
    justify-content: space-between;
}

.top-nav-bar-mobile .top {
    width: 100%;
	display: flex;
	margin: 0 20px;
	align-items: center;
	margin-bottom: 5px;
	justify-content:  space-between;
}

.top-nav-bar-mobile .top .left {
	width: 40%;
	display: flex;
	justify-content: flex-start;
}

.top-nav-bar-mobile .top .right {
	width: 60%;
	display: flex;
	justify-content: flex-end;
}

.top-nav-bar-mobile .top .right .image-flag {
	width: 30px;
	height: 30px;
	margin-left: 10px;
}

.top-nav-bar-mobile .top .phone-link {
    color: black;
}

.top-nav-bar-mobile .top .phone-link .flag-wrapper {
	display: flex;
	margin-bottom: -5px;
	justify-content: center;
}

.top-nav-bar-mobile .top .phone-link .phone-flag {
    width: 20px;
    height: 20px;
}

.top-nav-bar-mobile .bottom {
	width: 100%;
	margin: 0 20px;
}

.top-nav-bar-mobile .bottom .bottom-links {
    color: black;
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.top-nav-bar-mobile .bottom .bottom-links .top-nav-link-icon-wrapper {
    display: flex;
	justify-content: center;
}
