.nav-bar-desktop {
    top: 51.5px;
    z-index: 999;
    height: 80px;
    display: flex;
    padding: 0 20px;
    position: -webkit-sticky;
    position: sticky;
    align-items: center;
    background-color: #004B23;
    justify-content: space-between;
}

.nav-bar-desktop .main-logo-link {
	margin-left: 20px;
}

.nav-bar-desktop .main-logo-link .main-logo-img {
    width: 250px;
}

.nav-bar-desktop .nav-button {
    margin: 0 10px;
}
