.pricing-container {
    padding: 30px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.pricing-container .pricing-header {
	width: 100%;
}

.pricing-container .prices-info {
	max-width: 1000px;
    width: 60%;
	margin: 30px 0;
    padding: 20px;
}

.pricing-container .prices-info .price-info {
    font-size: 20px;
    border: 1px solid #dedede;
    border-radius: 5px;
    padding: 10px;
    margin: 5px 0;
}


/* MOBILE */
.pricing-container-mobile {
    padding: 30px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.pricing-container-mobile .pricing-header {
	width: 100%;
}

.pricing-container-mobile .prices-info {
	max-width: 1000px;
    width: 80%;
	margin: 30px 0;
    padding: 10px;
}

.pricing-container-mobile .prices-info .price-info {
    font-size: 18px;
    border: 1px solid #dedede;
    border-radius: 5px;
    padding: 10px;
    margin: 5px 0;
}