.settings-container {
    display: flex;
	flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.settings-container .prices-container {
	margin: 15px 0;
	width: 60%;
	padding: 20px;
}

.settings-container .prices-container .price-input {
	margin: 10px 0;
}

.settings-container .prices-container .submit-btn-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.settings-container .defaults-container {
	margin: 15px 0;
	width: 60%;
	padding: 20px;
}

.settings-container .defaults-container .econt-office-autocomplete {
	margin: 10px 0;
}

.settings-container .defaults-container .submit-btn-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.settings-container .defaults-container .auto-open-econt-labels-checkbox-group {
	-webkit-user-select: none;
	user-select: none;
	padding: 5px 20px;
	margin: 10px 0;
	border: 1px solid #999999;
	border-radius: 5px;
}
