.profile-no-internal-schedules {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    margin-top: 220px;
}

.mobile-internal-schedules-tab {
    padding: 0 !important;
}

.mobile-internal-schedules-tab .internal-schedule-delivery-card {
    padding: 20px;
    margin: 10px 0;
}

.mobile-internal-schedules-tab .internal-schedule-delivery-card.completed {
    background-color: #c1ffb8;
}

.mobile-internal-schedules-tab .internal-schedule-delivery-card .name-info-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mobile-internal-schedules-tab .internal-schedule-delivery-card .clipboard-copy-address {
    border: 1px solid #dedede;
    padding: 5px;
    border-radius: 5px;
}
