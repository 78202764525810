.forget-password-container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.forget-password-form-wrapper {
	width: 40%;
	margin: 40px 0;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.forget-password-form-wrapper .fp-header {
	width: 100%;
	text-align: center;
}

.forget-password-form-wrapper .fp-hint {
	width: 100%;
	text-align: center;
}

.forget-password-form-wrapper .fp-input-field {
	margin: 20px 0;
}

.forget-password-form-wrapper .forget-password-form {
	width: 100%;
}


/* MOBILE */
.forget-password-container-mobile {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.forget-password-container-mobile .forget-password-form-wrapper {
	width: 80%;
	display: flex;
	margin: 20px 0;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.forget-password-container-mobile .forget-password-form-wrapper .fp-header {
	width: 100%;
	margin-top: 30px;
	text-align: center;
}

.forget-password-container-mobile .forget-password-form-wrapper .fp-hint {
	width: 100%;
	text-align: center;
}

.forget-password-container-mobile .forget-password-form-wrapper .fp-email-input {
	margin: 20px 0;
}

.forget-password-container-mobile .forget-password-form-wrapper .forget-password-form-mobile {
	width: 100%;
}
