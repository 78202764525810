.home-register-user-mobile {
    margin-top: 40px;
}

.home-register-user-mobile .text-content {
	padding: 70px 0;
    width: 100%;
    height: 100%;
	background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
	background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
        linear-gradient(45deg, rgba(0, 255, 0, 0.4), rgba(255, 255, 255, 0.4), rgba(255, 0, 0, 0.4)),
        url('../../../../../assets/images/home-page_register-image-mobile.jpg');
}

.home-register-user-mobile .register-question-label {
    color: white;
    width: 80%;
    margin-left: 20px;
}

.home-register-user-mobile .register-now-label {
    color: white;
    font-weight: 600;
    width: 80%;
    margin: 0 0 10px 20px;
}

.home-register-user-mobile .register-now-button {
    margin-left: 20px;
}