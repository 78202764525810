.verify-registration-container {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
	margin-bottom: 40px;
    padding-bottom: 40px;
}

.verify-registration-form {
    width: 40%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    justify-content: flex-start;
}

.verify-registration-form .header {
    width: 100%;
	font-weight: 600;
    margin-top: 20px;
}

.verify-registration-input-fields {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 20px 0;
    justify-content: center;
}

.verify-registration-input-fields .helpers-section {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.verify-registration-input-fields .helpers-section .register-checkbox {
	width: 100%;
}

.verify-registration-container .verify-registration-input-fields .verify-registration-input-field {
    width: 100%;
    margin: 5px 0 !important;
}

.verify-registration-container .verify-registration-input-fields .submit-register {
    width: 60%;
    margin: 10px 0 !important;
}

.verify-registration-input-fields .error-labels {
    width: 100%;
    margin: 10px 0 5px 0;
}

.verify-registration-input-fields .action-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.verify-registration-input-fields .action-buttons.single-button {
    justify-content: center;
}

.verify-registration-input-fields .action-buttons .submit-button {
    width: 45%;
}


/* MOBILE */
.verify-registration-container-mobile {
    width: 100%;
    height: 50%;
    display: flex;
    padding: 20px 0;
    margin-bottom: 50px;
    justify-content: center;
}

.verify-registration-container-mobile .verify-registration-form {
	width: 80%;
    height: 100%;
    margin-top: 10px;
}

.verify-registration-container-mobile .verify-registration-input-fields {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.verify-registration-container-mobile .verify-registration-input-fields .verify-registration-input-field {
    width: 100%;
    margin: 5px 0;
}

.verify-registration-container-mobile .verify-registration-input-fields .action-buttons {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.verify-registration-container-mobile .verify-registration-input-fields .action-buttons .MuiButtonBase-root {
    width: 100%;
    margin-top: 10px;
}
