.perks-mobile {
    display: flex;
    padding: 20px 0;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
}

.perks-mobile-subsections {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.perks-mobile-subsections .subsection {
    width: 80%;
    padding: 20px;
    margin: 10px 0;
    border-radius: 5px;
    background-color: #eeeeee;
}

.perks-mobile-subsections .subsection .subsection-image {
    width: 30px;
    height: 30px;
}
