.home-steps-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.home-steps {
	display: flex;
    flex-wrap: wrap;
	max-width: 1400px;
    align-items: center;
    justify-content: center;
}

.home-steps .subsection {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.home-steps .subsection .info-content {
    width: 45%;
}

.home-steps .subsection .info-content .action-buttons button {
    margin: 15px 10px 0 0;
}

.home-steps .subsection .arrow-wrapper {
    width: 10%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 30px;
}

.arrow-wrapper .arrow-image {
    width: 100px;
    height: 100px;
    rotate: 90deg;
}

.arrow-wrapper .arrow-image.rotated {
    width: 100px;
    height: 100px;
    rotate: -90deg;
    transform: scaleX(-1);
}

.home-steps .subsection .image-wrapper {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-steps .subsection .image-wrapper .content-image {
    width: 250px;
    height: 250px;
}
