.restore-password-container-mobile {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.restore-password-form-wrapper-mobile {
	width: 80%;
	display: flex;
	margin: 40px 0;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.restore-password-form-wrapper-mobile .rp-header {
	width: 100%;
	text-align: center;
}

.restore-password-form-wrapper-mobile .rp-hint {
	width: 100%;
	text-align: center;
}

.restore-password-form-wrapper-mobile .rp-email-input {
	margin: 20px 0;
}

.restore-password-form-wrapper-mobile .restore-password-form {
	width: 100%;
}

.restore-password-form-wrapper-mobile .restore-password-form .rp-input-field {
	margin: 15px 0;	
}
