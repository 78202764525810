.internal-schedules-modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    padding: 20px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 2px 2px 10px black;
}

@media only screen and (max-width: 1024px) {
    .internal-schedules-modal-container {
		width: 90%;
    }
}

.internal-schedules-modal-container .modal-header {
	margin-bottom: 30px;
}

.internal-schedules-modal-container .timespan-inputs-wrapper {
	border: 1px solid #aaa;
	padding: 15px 10px 5px 10px;
	margin: 20px 0;
	background-color: #fafafa;
	border-radius: 5px;
	display: flex;
	justify-content: space-between;
}

.internal-schedules-modal-container .action-buttons {
	margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.internal-schedules-modal-container .action-buttons button {
    margin: 0 10px;
}
