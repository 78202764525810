.profile-mobile-no-completed-orders {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    margin-top: 40px;
}

.completed-orders-tab {
    padding: 0 !important;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.completed-orders-tab .completed-order-card {
    width: 80%;
    padding: 20px;
    margin: 10px 0;
    background: linear-gradient(#dedede, #c1ffb8)
}

.completed-orders-tab .completed-order-card .base-info-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.completed-orders-tab .completed-order-card .delivery-address {
    border: 1px solid #dedede;
    padding: 5px;
    border-radius: 5px;
}

.profile-orders-pagination-wrapper-mobile {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}