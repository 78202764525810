.how-it-works-step-container {
	padding: 40px 50px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-evenly;
}

.how-it-works-step-container .hiw-contents-wrapper {
	width: 50%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-evenly;
}

.how-it-works-step-container .hiw-contents-wrapper .hiw-sub-header {
	width: 100%;
}

.how-it-works-step-container .hiw-contents-wrapper .hiw-header {
	width: 100%;
}

.how-it-works-step-container .hiw-contents-wrapper .hiw-contents-description {
	padding: 20px;
	margin-top: 10px;
}

.how-it-works-step-container .hiw-image-wrapper {
	width: 20%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-evenly;
}

.how-it-works-step-container .hiw-arrow-wrapper {
	width: 10%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-evenly;
	rotate: -90deg;
	transform: scaleX(-1);
	margin-bottom: -200px;
}

.how-it-works-step-container .hiw-arrow-wrapper.rotated {
	rotate: 90deg;
	transform: scaleX(1);
}

.how-it-works-step-container .hiw-arrow-wrapper img {
	width: 100%;
	height: 100%;
}
