.footer-mobile {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
    justify-content: center;
}

.footer-mobile h6,
.footer-mobile p,
.footer-mobile a {
    color: white;
}

.footer-mobile .top-footer {
    width: 100%;
    display: flex;
	flex-wrap: wrap;
    padding: 20px 0;
    justify-content: center;
}

.footer-mobile .top-footer .footer-section {
    width: 100%;
    margin: 0 30px;
}

.footer-mobile .top-footer .footer-section .section-header {
	font-weight: 600;
}

.footer-mobile .top-footer .footer-section .section-links {
	display: flex;
    flex-wrap: wrap;
	margin: 5px 0 30px 0;
}

.footer-mobile .top-footer .footer-section .section-links a {
    width: 100%;
    margin: 2px 0;
}

.footer-mobile .bottom-footer {
    width: 100%;
    display: flex;
    padding: 20px;
	flex-wrap: wrap;
	justify-content: center;
}

.footer-mobile .bottom-footer .copyright-info-wrapper {
	margin-bottom: 20px;
}

.footer-mobile .bottom-footer .copyright-info-wrapper .project-12-logo {
	width: 110px;
	height: 30px;
	margin-left: 10px;
	margin-bottom: -10px;
}
