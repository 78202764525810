.create-label-modal-container .modal-contents-wrapper {
    padding: 10px;
}

.create-label-modal-container .econt-office-autocomplete {
    margin: 15px 0;
}

.create-label-modal-container .receiver-info {
    color: black;
    padding-left: 10px;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.create-label-modal-container .create-label-sizes-radio-option {
    padding-right: 10px;
    -webkit-user-select: none;
    user-select: none;
}

.create-label-sizes-radio-option.non-selected {
    border: 1px solid #999999;
}

.create-label-sizes-radio-option.none-selected {
    border: 1px solid red;
}

.create-label-sizes-radio-option.selected {
    border: 2px solid green;
}