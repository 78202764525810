.register-container {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
	margin-bottom: 50px;
}

.register-form {
    width: 40%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    justify-content: flex-start;
}

.register-form .header {
    width: 100%;
	font-weight: 600;
    margin-top: 20px;
}

.register-input-fields {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 20px 0 20px;
    justify-content: center;
}

.register-input-fields .helpers-section {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.register-input-fields .helpers-section .register-checkbox {
	width: 100%;
}

.register-input-fields .register-input-field {
    width: 100%;
    margin: 10px 0 10px !important;
}

.register-input-fields .submit-btn-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* MOBILE */
.register-container-mobile {
    width: 100%;
    height: 50%;
    display: flex;
    margin-bottom: 40px;
    padding-bottom: 40px;
    justify-content: center;
}

.register-container-mobile .register-form {
	width: 80%;
    height: 100%;
    margin-top: 30px;
}

.register-container-mobile .register-form .header {
    margin-top: 20px;
}

.register-container-mobile .register-input-fields {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 20px;
    flex-wrap: wrap;
}

.register-container-mobile .register-input-fields .register-input-field {
    width: 100%;
    margin: 10px 0 10px !important;
}

.register-container-mobile .register-input-fields .helpers-section {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}
