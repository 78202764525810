.MuiDialog-paper {
	width: 100% !important;
}

.modal-container {
    padding: 20px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 2px 2px 10px black;
}

.modal-container .edit-order-form .action-buttons {
    display: flex;
    justify-content: flex-end;
}

.modal-container .edit-order-form .action-buttons button {
    margin: 0 10px;
}

.modal-container .edit-order-form .edit-order-field {
	margin-bottom: 5px;
}

.modal-container .edit-order-form .edit-order-packages-manage {
	border: 1px solid #cbcbcb;
	margin: 10px 0;
	border-radius: 5px;
	padding: 10px;
}

.modal-container .edit-order-form .edit-order-fields-50-50-with-icon {
	width: 100%;
    display: flex;
    margin: 7px 0;
    align-items: center;
    justify-content: space-between;
}

.modal-container .edit-order-form .edit-order-fields-50-50-with-icon .edit-order-field-50 {
    width: 44%;
}
