.register-order-banner-mobile {
    width: 100%;
}

.register-order-banner-mobile .text-content {
    margin-top: 20px;
    padding: 50px 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
        linear-gradient(90deg, rgba(0, 255, 0, 0.4), rgba(255, 255, 255, 0.4), rgba(255, 0, 0, 0.4)),
        url('../../../assets/images/home-page_register-order-image-mobile.jpg');
}

.register-order-banner-mobile .order-question-label {
    color: white;
    width: 80%;
    margin-left: 20px;
}

.register-order-banner-mobile .register-order-label {
    color: white;
    font-weight: 600;
    width: 80%;
    margin: 0 0 10px 20px;
}

.register-order-banner-mobile .register-order-button {
    margin-left: 20px;
}
