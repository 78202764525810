.price-calc-packages-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.price-calc-packages-container .price-calc-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.price-calc-packages-container .price-calc-form-paper {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
	max-width: 1600px;
    align-items: center;
    justify-content: center;
}

.price-calc-packages-container .header-animation {
	width: 100%;
}

.price-calc-packages-container .price-calc-header {
    margin: 10px 0 20px 0;
    text-align: center;
    font-weight: 600;
	width: 100%;
}

.price-calc-packages-container .inputs-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.price-calc-packages-container .inputs-row .price-calc-input-field {
    margin: 10px 0;
    width: 24%;
}

.price-calc-packages-container .inputs-row .price-calc-input-field-2 {
    margin: 10px 0;
    width: 19%;
	display: flex;
	justify-content: center;
}

.price-calc-packages-container .inputs-row .price-calc-field-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.price-calc-packages-container .inputs-row .price-calc-field {
    margin: 10px 0;
    width: 49%;
}

.price-calc-packages-container .inputs-row .price-calc-input-field-request-row1 {
    width: 32%;
    margin: 10px 0;
}

.price-calc-packages-container .inputs-row .price-calc-input-field-request-row2 {
    width: 100%;
    margin: 10px 0;
}
