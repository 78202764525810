.nav-bar-mobile {
    top: 90px;
    width: 100%;
    z-index: 999;
    padding: 10px 0;
    position: fixed;
    background-color: #004b23;
}

.nav-bar-mobile .main-logo-link {
    margin-left: 10px;
}

.nav-bar-mobile .main-logo-link .main-logo-img {
    width: 80px;
}

.nav-bar-mobile .mobile-nav-elements {
    display: flex;
    flex-wrap: wrap;
    padding: 0 5px;
    align-items: center;
    justify-content: space-between;
}

.register-order-nav-button {
    font-size: 10px !important;
    padding: 7px 12px 4px 12px !important;
}
