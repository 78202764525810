.tac-container {
	padding: 30px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.tac-container .tac-header {
	margin-bottom: 20px;
	width: 100%;
}

.tac-container .general-terms-container {
	max-width: 1600px;
}

.tac-container .general-term-container {
	margin: 1% 5%;
	padding: 3% 4%;
}

@media screen and (max-width: 1000px) {
    .tac-container .general-term-container {
		margin: 5%;
		padding: 5%;
	}
}

.tac-container .general-term-container .general-term-header {
	margin-bottom: 20px;
}

.tac-container .general-term-container .term-text {
	font-size: 18px;
}

.tac-container .general-term-container .subterm-text {
	font-size: 16px;
}