.top-controls {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.top-control-group-mobile {
    width: 100%;
    margin: 20px 0;
}
