.weight-and-sizes-wrapper {
    padding: 30px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.weight-and-sizes-container {
	max-width: 1400px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.weight-and-sizes-container .was-header,
.weight-and-sizes-container .was-request,
.weight-and-sizes-container .prices-info,
.weight-and-sizes-container .was-summary {
    margin-bottom: 30px;
    width: 100%;
    padding: 0 15%;
}

.weight-and-sizes-container .was-info {
    padding: 0 15%;
    width: 100%;
}

.weight-and-sizes-container .was-formula {
    width: 100%;
    padding: 0 15%;
    margin-bottom: 30px;
    text-decoration: underline;
}

.weight-and-sizes-container .prices-info {
    width: 60%;
    padding: 20px;
}

@media screen and (max-width: 500px) {
    .weight-and-sizes-container .prices-info {
        width: 80%;
    }
}
