.admin-add-orders-to-cb-modal {
    overflow: scroll;
}

.cross-border-schedules-orders-modal-container {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    width: 80%;
    padding: 20px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 2px 2px 10px black;
}

@media only screen and (max-width: 1024px) {
    .cross-border-schedules-orders-modal-container {
		width: 90%;
    }
}

.cross-border-schedules-orders-modal-container .orders-container {
	margin: 20px 0;
}

.cross-border-schedules-orders-modal-container .orders-container .picked-row {
	background-color: #e6ffe3;
}

.cross-border-schedules-orders-modal-container .orders-container .unpicked-row {
	background-color: #ffe3e3;
}

.cross-border-schedules-orders-modal-container .action-buttons {
	margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.cross-border-schedules-orders-modal-container .action-buttons button {
    margin: 0 10px;
}