.contacts-container .top-contents {
    display: flex;
	margin: 30px 0;
	justify-content: space-evenly;
}

.contacts-container .left {
    width: 45%;
}

.contacts-container .left .left-header {
    width: 100%;
    margin: 20px 0;
}

.contacts-container .left .phones {
    margin: 50px 0;
}

.contacts-container .left .phones .phone-info {
    margin-top: 10px;
}

.contacts-container .right {
    width: 45%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.contacts-container .right .captcha-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.contacts-container .right .right-header {
    width: 100%;
    margin: 20px 0;
}

.contacts-container .right .contacts-form {
    width: 100%;
}

.contacts-container .right .contacts-form .contacts-input-field {
    margin: 10px 0;
}

.contacts-container .right .contacts-form .submit-button-wrapper {
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contacts-container .register-order-banner {
    margin-top: 80px;
}

.contacts-container .contact-icon {
    margin-left: 5px;
    margin-bottom: -5px;
}