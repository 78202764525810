.profile-information-container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.profile-information-container .profile-information-header {
	width: 100%;
	margin: 20px 0;
	font-weight: 600;
	text-align: center;
}

.profile-information-container .profile-information-form {
	width: 45%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.profile-information-container .profile-information-form .profile-information-inputs-only {
	width: 100%;
}

.profile-information-container .profile-information-form .profile-information-input-field {
	margin: 10px 0;
}

.profile-information-container .profile-information-form .submit-profile-information {
	margin: 20px 0;
	width: 60%;
}


/* MOBILE */
.profile-information-container-mobile {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.profile-information-container-mobile .profile-information-header {
	width: 100%;
	margin: 20px 0;
	font-weight: 600;
	text-align: center;
}

.profile-information-container-mobile .profile-information-form {
	width: 70%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.profile-information-container-mobile .profile-information-form .profile-information-inputs-only {
	width: 100%;
}

.profile-information-container-mobile .profile-information-form .profile-information-input-field {
	margin: 10px 0;
}

.profile-information-container-mobile .profile-information-form .submit-profile-information {
	margin: 20px 0;
	width: 100%;
}
