.home-steps-mobile {
	overflow-x: scroll;
	display: flex;
	margin-top: 90px;
	margin-bottom: 20px;
}

.home-steps-mobile .subsection {
	min-width: 250px;
	min-height: 350px;
	margin: 0 30px;
	background-color: #eeeeee;
	padding: 20px 30px 10px 30px;
}

.home-steps-mobile .subsection .info-content {
	display: flex;
	height: 100%;
	flex-wrap: wrap;
}

.home-steps-mobile .subsection .info-content .subsection-header {
	width: 100%;
	height: 15%;
}

.home-steps-mobile .subsection .info-content .image-wrapper {
	width: 100%;
	height: 30%;
	display: flex;
	justify-content: center;
}

.home-steps-mobile .subsection .info-content .image-wrapper .content-image {
	width: 100px;
	height: 100px;
}

.home-steps-mobile .subsection .info-content .subsection-description {
	height: 30%;
}

.home-steps-mobile .subsection .info-content .action-buttons {
	width: 100%;
	display: flex;
	height: 10%;
	justify-content: space-evenly;
}