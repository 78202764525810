.delivery-time-container {
	padding: 30px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.delivery-time-container .delivery-time-header {
	margin-bottom: 30px;
    width: 100%;
    padding: 0 15%;
}

.delivery-time-container .delivery-time-info-wrapper {
	padding: 0 15%;
	max-width: 1000px;
}

.delivery-time-container .delivery-time-info-wrapper .delivery-time-description {
	margin-bottom: 20px;
}
