.internal-schedule-order-details-modal-wrapper {
    display: flex;
    width: 100%;
    height: 30%;
    align-items: center;
    justify-content: center;
}

.internal-schedule-order-details-modal-container {
    position: absolute;
    bottom: 50px;
    width: 85%;
    /* min-height: 50%; */

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
}

.internal-schedule-order-details-modal-container .top-section {
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.internal-schedule-order-details-modal-container .top-section .order-receiver {
    width: 80%;
}

.internal-schedule-order-details-modal-container .top-section .close-modal-icon {
    width: 20%;
}

.internal-schedule-order-details-modal-container .mid-section {
    width: 100%;
    height: 60%;
    margin: 20px 0;
}

.internal-schedule-order-details-modal-container .mid-section .price-details {
    border: 1px solid #dedede;
    padding: 5px;
    border-radius: 5px;
    margin: 10px 0;
}

.internal-schedule-order-details-modal-container .bot-section {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
}