.price-calc-pallets-container-mobile {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.price-calc-pallets-container-mobile .price-calc-form {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.price-calc-pallets-container-mobile .price-calc-form-paper {
	width: 100%;
	display: flex;
	padding: 20px;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.price-calc-pallets-container-mobile .price-calc-header {
	margin: 10px 0 20px 0;
	text-align: center;
	font-weight: 600;
    width: 100%;
}