.home-register-user {
    margin-top: 100px;
    margin-bottom: 130px;
}

.home-register-user .text-content {
    padding: 70px 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
	background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
        linear-gradient(45deg, rgba(0, 255, 0, 0.4), rgba(255, 255, 255, 0.4), rgba(255, 0, 0, 0.4)),
        url('../../../../../assets/images/home-page_register-image.jpg');
}

.home-register-user .register-question-label {
    color: white;
    margin: 0 0 10px 200px;
}

.home-register-user .register-now-label {
    color: white;
    font-weight: 600;
    margin: 0 0 20px 200px;
}

.home-register-user .register-now-button {
    margin-left: 200px;
}
