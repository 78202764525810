.register-order-container-mobile {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
    align-items: center;
    justify-content: center;
}

.register-order-container-mobile .register-order-header {
	width: 100%;
	margin: 20px 0;
	font-weight: 600;
	text-align: center;
}

.register-order-container-mobile .register-order-form-mobile {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.register-order-container-mobile .register-order-form-mobile .register-order-field {
    width: 100%;
    margin: 7px 0;
	display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.register-order-container-mobile .register-order-form-mobile .register-order-packages-manage {
	border: 1px solid #cbcbcb;
	margin: 10px 0;
	border-radius: 5px;
	padding: 10px;
}

.register-order-container-mobile .register-order-form-mobile .register-order-fields-50-50-with-icon {
	width: 100%;
    display: flex;
    margin: 7px 0;
    align-items: center;
    justify-content: space-between;
}

.register-order-container-mobile .register-order-form-mobile .register-order-fields-50-50-with-icon .register-order-field-50 {
    width: 40%;
}
