.faq-container {
	padding: 30px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.faq-container .faq-header {
	margin-bottom: 30px;
    width: 100%;
    padding: 0 15%;
}

.faq-accordions {
	width: 80%;
	max-width: 1000px;
}

@media screen and (max-width: 700px) {
	.faq-accordions {
		width: 100%;
	}	
}
