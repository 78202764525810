.fobidden-items-container {
    padding: 30px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.fobidden-items-container .fobidden-items-header {
    width: 100%;
	margin-bottom: 30px;
}

.fobidden-items-container .forbidden-list {
    width: 70%;
	max-width: 1200px;
}

@media screen and (max-width: 700px) {
    .fobidden-items-container .forbidden-list {
        width: 90%;
    }
}

.fobidden-items-container .forbidden-list .forbidden-list-item {
    padding: 5px;
    border-radius: 3px;
    border: 1px solid white;
}

.fobidden-items-container .forbidden-list .forbidden-list-item:hover {
    border: 1px solid black;
}
