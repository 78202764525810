.settings-container-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
}

.settings-container-mobile .prices-container-mobile {
	width: 100%;
	padding: 20px 10px;
}

.settings-container-mobile .prices-container-mobile .price-input {
	margin: 10px 0;
}

.settings-container-mobile .prices-container-mobile .submit-btn-container {
	display: flex;
	align-items: center;
	justify-content: center;
}
