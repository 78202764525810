.price-calc-top-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.price-calc-top-box-mobile {
    margin-top: 180px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.price-calc-top-box .price-calc-top-paper {
    width: 80%;
}

.price-calc-top-box-mobile .price-calc-top-paper {
    width: 90%;
}

.price-calc-top-box .MuiTabs-flexContainer, .price-calc-top-box-mobile .MuiTabs-flexContainer {
    justify-content: center;
}
