.footer {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
    justify-content: center;
}

.footer h6,
.footer p,
.footer a {
    color: white;
}

.footer .top-footer {
    width: 100%;
    display: flex;
    padding: 20px 0;
    justify-content: center;
}

.footer .top-footer .footer-section {
    width: 15%;
    margin: 0 10px;
}

.footer .top-footer .footer-section.logo-wrapper {
	margin-right: 80px;
}

.footer .top-footer .footer-section.logo-wrapper .footer-logo-main {
    width: 250px;
}

.footer .top-footer .footer-section .section-links {
    display: flex;
    flex-wrap: wrap;
}

.footer .top-footer .footer-section .section-links a {
    width: 100%;
    margin: 3px 0;
}

.footer .bottom-footer {
    width: 100%;
    display: flex;
    padding: 0 150px;
	align-items: center;
    justify-content: space-between;
}

.footer .bottom-footer .copyright-info-wrapper .project-12-logo {
	width: 110px;
	height: 30px;
	margin-left: 10px;
	margin-bottom: -10px;
}
