.profile-mobile-no-active-orders {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    margin-top: 40px;
}

.active-orders-tab {
    padding: 0 !important;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.active-orders-tab .active-order-card {
    width: 80%;
    padding: 20px;
    margin: 10px 0;
    background: linear-gradient(#dedede, #ffffff)
}

.active-orders-tab .active-order-card.completed {
    background-color: #c1ffb8;
}

.active-orders-tab .active-order-card .base-info-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.active-orders-tab .active-order-card .delivery-address {
    border: 1px solid #dedede;
    padding: 5px;
    border-radius: 5px;
}