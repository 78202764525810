.cross-border-schedules-container .top-row {
    display: flex;
	align-items: center;
    justify-content: flex-end;
    margin: 10px 0;
}

.cross-border-schedules-container .top-row .top-action-button {
	margin-left: 10px;
}
