.contacts-container-mobile .top-contents {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0;
}

.contacts-container-mobile .top {
    width: 100%;
    margin-left: 20px;
}

.contacts-container-mobile .top .top-header {
    width: 100%;
    margin: 20px 0;
    font-weight: 600;
}

.contacts-container-mobile .top .phones {
    margin: 40px 0;
}

.contacts-container-mobile .top .phones .phone-info {
    margin-top: 10px;
}

.contacts-container-mobile .bottom {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
}

.contacts-container-mobile .bottom .bottom-header {
    width: 100%;
    font-weight: 600;
    margin: 20px 0 20px 20px;
}

.contacts-container-mobile .bottom .contacts-form-mobile {
    width: 100%;
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.contacts-container-mobile .bottom .contacts-form-mobile .contacts-input-field {
    margin: 10px 0;
    width: 100%;
}

.contacts-container-mobile .bottom .contacts-form-mobile .submit-button-wrapper {
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contacts-container-mobile .contact-icon {
    margin-left: 5px;
    margin-bottom: -5px;
}
