.landing-mobile {
    height: 170px;
    margin-top: -32px;
    margin-bottom: 40px;
}

.landing-mobile .text-content {
    padding: 120px 0 250px 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
        linear-gradient(90deg, rgba(0, 255, 0, 0.2), rgba(255, 255, 255, 0.2), rgba(255, 0, 0, 0.2)),
        url('../../../../../assets/images/home-page_main-image-mobile.png');
}

.landing-mobile .top-label {
    margin-bottom: 10px;
    margin-left: 20px;
    color: white;
}

.landing-mobile .middle-label {
    margin-bottom: 10px;
    margin-left: 20px;
    font-weight: 600;
    color: white;
}

.landing-mobile .bottom-label {
    margin-bottom: 10px;
    margin-left: 20px;
    color: white;
}
