.orders-top-controls {
	display: flex;
	justify-content: space-between;
}

.row-delivery-company-tuttopost {
	background-color: #00ff0010;
}

.row-delivery-company-econt {
	background-color: #0000ff10;
}

.MuiDataGrid-row.row-delivery-company:hover {
	border: 1px solid black;
	border-style: dashed;
	cursor: pointer;
}
