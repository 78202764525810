.price-calc-freight-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.price-calc-freight-container .price-calc-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.price-calc-freight-container .price-calc-form-paper {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    max-width: 1600px;
    align-items: center;
    justify-content: center;
}

.price-calc-freight-container .header-animation {
    width: 100%;
}

.price-calc-freight-container .price-calc-header {
    margin: 10px 0 20px 0;
    text-align: center;
    font-weight: 600;
    width: 100%;
}
