.how-it-works-wrapper {
	width: 100%;
    display: flex;
	margin: 30px 0;
    flex-wrap: wrap;
    justify-content: center;
}

.how-it-works-container {
    width: 100%;
	display: flex;
    flex-wrap: wrap;
	max-width: 1600px;
    justify-content: center;
}

.how-it-works-container .how-it-works-header {
    width: 100%;
}
