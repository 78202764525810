.admin-add-orders-to-is-modal {
    overflow: scroll;
}

.internal-schedules-orders-modal-container {
    left: 50%;
    width: 80%;
    padding: 20px;
    border-radius: 5px;
    position: absolute;
    background-color: white;
    transform: translate(-50%, 0);
    box-shadow: 2px 2px 10px black;
}

@media only screen and (max-width: 1024px) {
    .internal-schedules-orders-modal-container {
		width: 90%;
    }
}

.internal-schedules-orders-modal-container .orders-container {
	margin: 20px 0;
}

.internal-schedules-orders-modal-container .orders-container .picked-row {
	background-color: #e6ffe3;
}

.internal-schedules-orders-modal-container .orders-container .unpicked-row {
	background-color: #ffe3e3;
}

.internal-schedules-orders-modal-container .action-buttons {
	margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.internal-schedules-orders-modal-container .action-buttons button {
    margin: 0 10px;
}